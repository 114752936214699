@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
* {
  outline: 0;
  text-decoration: none !important;
}

body {
  font-family: "Poppins", "fontAwesome", sans-serif;
  font-size: 14px;
}

a,
.btn-link {
  color: #13a3d8;
  cursor: pointer;
}

.nav-item {
  cursor: pointer;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #454b60;
  font-size: 13px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #454b60;
  font-size: 13px;
}

::placeholder {
  color: #454b60;
  font-size: 13px;
}

.min-height-100vh {
  min-height: 100vh;
}

.bg-none {
  background: transparent !important;
}

a {
  color: #13a3d8;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-size-9 {
  font-size: 9px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.bg-secondary {
  background: #002f5b !important;
}

.bg-primary {
  background: #13a3d8 !important;
}

.text-primary {
  color: #13a3d8 !important;
}

.btn-primary {
  background: #13a3d8 !important;
  border-color: #13a3d8 !important;
}

.text-dark {
  color: #000 !important;
}

.text-success {
  color: #3ec898 !important;
}

.text-danger {
  color: #f26578 !important;
}

/*--------Badge outlines----------*/

.badge {
  border: 1px solid transparent;
}

.badge.badge-default {
  background-color: #747474;
}

.badge.badge-outlined {
  background-color: transparent;
}

.badge.badge-outlined.badge-default {
  border-color: #747474;
  color: #747474;
}

.badge.badge-outlined.badge-primary {
  border-color: #13a3d8;
  color: #13a3d8;
}

.badge.badge-outlined.badge-secondary {
  border-color: #323a45;
  color: #323a45;
}

.badge.badge-outlined.badge-success {
  border-color: #3ec898;
  color: #3ec898;
}

.badge.badge-outlined.badge-warning {
  border-color: #dec53c;
  color: #dec53c;
}

.badge.badge-outlined.badge-info {
  border-color: #29b6f6;
  color: #29b6f6;
}

.badge.badge-outlined.badge-danger {
  border-color: #f26578;
  color: #f26578;
}

/*--------.Badge outlines----------*/

.dropdown-menu {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  border-radius: 3px 3px 0px 0px;
  border-color: #eaeaea;
  border-bottom: 4px solid #13a3d8;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #13a3d8 !important;
}

.dropdown-item:focus {
  outline: 0;
}

.dropdown-item {
  font-size: 12px;
  font-weight: 600;
  padding: 10px 12px;
  color: #333a4a;
}

.modal-content {
  border: 0;
  border-bottom: 4px solid #13a3d8;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

.form-control {
  height: 45px;
  font-size: 13px;
}

.form-control:focus {
  outline: 0;
  box-shadow: none;
}

.form-control-file {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-sm {
  height: 28px;
  font-size: 12px;
  line-height: normal;
}

/* ---Login section--- */

.login-bg-image {
  background: url("../assets/images/loginBackground-image.png") no-repeat center;
  background-size: cover;
}

.login-card.card {
  border: 2px solid #00396f;
  background: #023260 !important;
  max-width: 395px;
  margin: 0 auto;
}

.bookmark-icon {
  right: 30px;
  top: 0;
  background: #2d5577;
  padding: 10px 3px 3px 3px;
  border-radius: 0 0 10px 10px;
}

.white-links-separator .nav-item:not(:last-child) {
  position: relative;
}

.white-links-separator .nav-item:not(:last-child):after {
  content: "";
  position: absolute;
  top: 0.6rem;
  bottom: 0.6rem;
  right: 0;
  border-right: 1px solid white;
}

/* ---.Login section--- */

/*---- app header----*/

.app-header {
  z-index: 99999;
  height: 62px;
}

.notification-badge {
  position: absolute;
  padding: 3px;
  right: -4px;
  display: inline-block !important;
  top: -1px;
}

/*---- .app header----*/

/*------sidebar--------*/

.left-navbar__width {
  width: 210px;
}

.content-block__width {
  width: calc(100vw - 210px);
  margin-top: 62px;
  overflow-y: auto;
  height: calc(100vh - 62px);
  background: #eff5fa;
}
.content-block__width .fa-plus-circle {
  cursor: pointer;
}

.sidebar {
  position: fixed;
  top: 62px;
  padding: 25px 0px;
  height: calc(100vh - 62px);
  bottom: 0;
  left: 0;
  z-index: 100;
  background-image: linear-gradient(#004f94, #002e59);
}

.sidebar .nav-item a {
  color: white;
  font-size: 14px;
  padding: 15px 15px 15px 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.sidebar .nav-item .active {
  background: #02294e;
  box-shadow: 0px 3px 6px #00000024;
}

.sidebar .nav-icon {
  width: 16px;
  margin-right: 8px;
}

/*------.sidebar--------*/

/*----Pages layout-------*/

.page-details-stripe {
  background: #eaeef2;
}

.stripe-heading {
  color: #000000;
  font-size: 16px;
}

/*----.Pages layout-------*/

/*-----Dashboard-------*/

.border-top-width-4 {
  border-top-width: 4px;
  border-color: #f8f8f8;
}

.border-top-muted {
  border-top-color: #747474;
}

.border-top-success {
  border-top-color: #3ec898;
}

.border-top-warning {
  border-top-color: #dec53c;
}

.card-inactive-bg {
  background: #f46979;
  border-color: transparent;
}

.inactive-arrow-icon {
  position: absolute;
  right: 10px;
  top: calc(50% - 8px);
}

.dashboard-status-tab .nav-item {
  margin: 5px 20px 5px 10px;
}

.dashboard-status-tab .nav-link {
  border-radius: 60px;
  font-weight: 500;
  padding: 7px 25px;
}

.dashboard-status-tab .nav-link.active {
  background: #13a3d8;
  color: #fff;
  border-color: transparent;
}

.dashboard-status-child-tab .nav-link {
  color: #000000;
  font-size: 12px;
  border: 0;
  position: relative;
}

.dashboard-status-child-tab .nav-item:not(:last-child) .nav-link:after {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 10px;
  border-right: 1px solid gray;
  right: 0;
}

.dashboard-status-child-tab .nav-link.active {
  font-weight: 600;
  background: transparent;
  border: 0;
  color: #000000;
}

.dashboard-status-child-tab .nav-link:hover {
  color: #000000;
}

.game-remote-size {
  font-size: 30px;
}

.status-card-popover .popover {
  border: 0;
}

.ellipsis-icon-color {
  color: #d0d0d0;
}

.configuration-table td {
  padding: 6px;
  font-size: 12px;
  line-height: 26px;
  color: #333a4a;
  border-color: #eeeeee !important;
}

.configuration-table table tr:nth-child(1) td {
  box-shadow: 2px 1px 4px #c7c7c7;
  font-weight: 500;
  position: relative;
}

.configuration-table table tr:nth-child(even) td {
  background: #fff;
}

.configuration-table table tr:nth-child(odd) td {
  background: #fafafa;
}

.configuration-table table tr td:first-child {
  padding-left: 20px;
}

.configuration-table table tr td:last-child {
  padding-right: 20px;
}

.configuration-table .ht_master,
.configuration-table .wtHolder,
.configuration-table .wtHider,
.configuration-table table {
  width: 100% !important;
}

/*--------Status Navigation filter--------*/

.status-borard-wrapper {
  height: calc(100% - 58px);
  overflow-y: auto;
}

.status-filter-nav {
  background: #eaeef2;
  max-height: calc(100vh - 120px);
  overflow: auto;
}

.status-filter-nav .nav {
  margin-bottom: 20px;
}

.status-filter-nav .nav-link {
  color: #333a4a;
  font-weight: 500;
  line-height: 24px;
  /* border-bottom: 1px solid #E3E3E3; */
  padding: 10px 16px;
}

.status-filter-nav .nav-link.active {
  background: #003e76;
  color: #fff;
}

.status-filter-nav .nav-link .badge {
  min-width: 40px;
  padding: 4px 8px;
  text-align: center;
}

.nav-category-head {
  background: #e0e7fa;
  line-height: 27px;
}

/*--------.Status Navigation filter--------*/

.ag-grid-react {
  width: 100%;
  height: 100%;
}

.react-toast-notifications__container {
  z-index: 999999999999 !important;
}

.rag-red {
  background-color: lightcoral;
}

.rag-green {
  background-color: lightgreen;
}

.rag-amber {
  background-color: lightsalmon;
}

.rag-yellow {
  background-color: #dec53c;
}

.rag-dark {
  background-color: #d6d8d9;
}

.select-card-checkbox {
  top: 5px;
  left: 5px;
  z-index: 1;
  display: inline-block;
  width: auto;
}

.select-status-card:hover {
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.12) !important;
}

.select-card-checkbox:checked + .select-status-card:before {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(2, 42, 78, 0.05);
  position: absolute;
  z-index: 0;
}

.play-pause-stop {
  background: #1fa3d8;
  padding: 3px 5px;
  border-radius: 4px;
}

.play-pause-stop button {
  display: inline-block;
  padding: 0 16px;
  vertical-align: middle;
  background: unset;
  border: unset;
  color: #fff;
}

.play-pause-stop button:disabled {
  color: rgba(255, 255, 255, 0.4);
}

.play-pause-stop button.pauseBtn {
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}

.rc-collapse-item .rc-collapse-content {
  padding: 0;
}

.rc-collapse-item .rc-collapse-content .rc-collapse-content-box {
  margin: 0;
}

.rc-collapse > .rc-collapse-item.group_sidebar > .rc-collapse-header {
  background: #003e76;
  color: #fff;
}

.cardimage {
  position: relative;
}

.imagestatus {
  position: absolute;
  top: 3px;
  right: 10px;
}

.textEllipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.start-time {
  /* top: 18px;
    bottom: -10px;
    position: absolute;
    left: 34px; */
}

.fil_btn span.badge {
  margin-left: 8px;
  color: #fff;
  vertical-align: middle;
  height: 19px;
  width: 19px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
}

.fil_btn span.badge button.close {
  color: #fff;
  float: unset;
  font-size: 15px;
  vertical-align: middle;
  font-weight: 300;
  line-height: 1.2;
}

.filter_footer {
  padding: 6px 13px;
}

.fil_btn {
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  padding: 5px 8px;
  margin-right: 10px;
}

input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

.filter_popover .popover {
  max-width: 350px;
  width: 100%;
}

.filter_input {
  display: flex;
}

.filter_input input {
  border-radius: 0.25rem 0 0 0.25rem;
}

.filter_input button {
  border-radius: 0 0.25rem 0.25rem 0;
}

.filter_popover h3.popover-header span.badge {
  float: right;
  color: #fff;
  height: 24px;
  width: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  position: static;
  align-items: flex-start;
}

.filter_popover span.badge button.close {
  color: #fff;
  float: unset;
  font-size: 21px;
  font-weight: 300;
  line-height: 16px;
  outline: none;
}

.filter_popover .popover-body {
  padding-bottom: 20px;
}

.filter_popover .popover {
  box-shadow: 4px 6px 9px 2px rgba(0, 0, 0, 0.4);
}

.checkbox-alignment {
  display: inline-block;
  width: auto;
}

.password-key {
  display: flex;
  align-items: center;
}

.date-picker {
  height: 28px;
}

.date-picker input {
  height: 28px;
}

.single-line {
  display: flex;
  align-items: baseline;
}

.single-line input {
  margin: 8px;
}
